h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
}

.bht_header {
	.header-slide {
		position: relative;
			.hero_slide {
				transition: none;
                li {
                    &.active {
                             animation: slideShow 10s infinite;
    animation-direction: alternate;
                    }
                }
               
			}
		.hero_caption {
	
			position: absolute;
			top: 38%;
			left: 0;
            right: 0;
//			transform: translateX(-50%) translateY(-50%);
			z-index: 10;
			font-family: $hero;
			color: #fff;
                text-shadow: 0 0 10px #000;
			.welcome {
				@include font-size(14px, 30px);
				margin-bottom: 20px;
			}
			.title {
						text-transform: uppercase;
				@include font-size(20px, 50px);
				font-weight: 500;
				color:  #fff;
				letter-spacing: 2px;
				margin-bottom: 20px;
				span {
					font-family: $hero;
					color:  #fff;
				}
			}
			p {
				font-family: $hero;
				@include font-size(9px, 30px);
			}
			h4 {
				@include font-size(11px, 30px);
			}
			img {
				margin: 0 auto 20px;
			}
		}
		}
}




@keyframes 
slideShow {  
0% {
transform:scale(1.1);
}
 100% {
 transform:scale(1);
}
}



@media (min-width: 768px) {
	.bht_header {
		
	}
}

@media (min-width: 992px) {
	.bht_header {
		padding-top: 126px;
		.header-slide {
			.slides {
				>li {
					background-attachment: fixed;
				}
			}
		}
		
	}
}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (max-width: 991px) {
	.bht_header {
		.header-slide {
			.slides {
				>li {
					background-position: right center;
				}
			}
		}
	}
	
}

@media (max-width: 991px) and (min-width: 768px) {
	.bht_header {
		
	}
}

@media (max-width: 767px) {
	.bht_header {
		.header-slide {
			.hero_caption {
					top: 43%;
					left: 37%;
				.welcome {
					margin-bottom: 10px;
				}
				img {
					margin-bottom: 10px;
					width: 58px;
				}
				.title {
					margin-bottom: 10px;
				}
			}
		}
	}
	
	
}

@media (max-width: 480px) {
	.bht_header {
		
	}
}

@media (min-width: 1500px) {}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: landscape) {
	
}

@media (min-width: 992px) and (max-width: 1300px) {
	.bht_header {
		
	}
}



@import "navbar";
@import "boxsearch";