.bht_header {
	.container-box-search {
		position: relative;
		padding: 40px 0;
		//		background-image: url('../images/bgbox.jpg');
		background-color: #FFFFFF;

		.title_box_search {
			margin-bottom: 30px;

			.title {
				font-style: italic;
				font-family: $headfont;
				color: #676767;
				@include font-size(23px,
				30px);

				span {
					font-family: $headfont;
					@include font-size(23px,
					30px);
					color: $book;
				}
			}
		}

		.box_search {
			input {
				background-color: transparent;
				border-radius: 0;
				border: 1px solid rgba(216, 216, 216, 0.46);
				color: $book;
				font-family: $mainfont;
				font-size: 14px;
				height: 60px;
				padding: 10px 25px;
				letter-spacing: 1px;
			}

			#dateci,
			#dateco {
				background-image: url('../images/down.png');
				background-repeat: no-repeat;
				background-position: 94% center;
				background-size: 21px;
			}

			#btnBook {
				width: 100%;
				background-color: $book;
				border-radius: 0;
				border-color: $maincolor;
				font-family: $headfont;
				text-transform: uppercase;
				height: 60px;
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 2.4em;
			}
		}
	}

	.picker__day--outfocus {
		color: $black;
	}
}

@media screen and (max-width: 991px) {
	.bht_header {
		.group_input {
			margin-bottom: 20px;
		}
	}
}
