/* This stylesheet generated by Transfonter (https://transfonter.org) on December 6, 2017 3:15 AM */

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BoldItalic.eot');
    src: url('../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-BoldItalic.woff') format('woff'),
        url('../fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.eot');
    src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Bold.woff') format('woff'),
        url('../fonts/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../fonts/Lato-Hairline.eot');
    src: url('../fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Hairline.woff') format('woff'),
        url('../fonts/Lato-Hairline.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Regular.eot');
    src: url('../fonts/Arapey-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Arapey-Regular.woff') format('woff'),
        url('../fonts/Arapey-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Italic.eot');
    src: url('../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Italic.woff') format('woff'),
        url('../fonts/Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.eot');
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Regular.woff') format('woff'),
        url('../fonts/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-LightItalic.eot');
    src: url('../fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-LightItalic.woff') format('woff'),
        url('../fonts/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Black.eot');
    src: url('../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Black.woff') format('woff'),
        url('../fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('../fonts/Lato-HairlineItalic.eot');
    src: url('../fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-HairlineItalic.woff') format('woff'),
        url('../fonts/Lato-HairlineItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.eot');
    src: url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Light.woff') format('woff'),
        url('../fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Italic.eot');
    src: url('../fonts/Arapey-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Arapey-Italic.woff') format('woff'),
        url('../fonts/Arapey-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-BlackItalic.eot');
    src: url('../fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-BlackItalic.woff') format('woff'),
        url('../fonts/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}


@font-face {
    font-family: 'HK Nova Medium R';
    src: url('../fonts/HKNova-MediumR.eot');
    src: url('../fonts/HKNova-MediumR.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HKNova-MediumR.woff') format('woff'),
        url('../fonts/HKNova-MediumR.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Vidaloka';
    src: url('../fonts/Vidaloka-Regular.eot');
    src: url('../fonts/Vidaloka-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Vidaloka-Regular.woff') format('woff'),
        url('../fonts/Vidaloka-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
