.bht_footer {
	.sub-footer {
		position: relative;

		.our-sister {
			margin-top: 30px;

			@media screen and (min-width: 1200px) {
				bottom: -55px;
				position: absolute;
				height: 100%;
			}

			@media screen and (max-width: 1199px) {
				img {
					    width: 90%;
				}
			}
		}

	}
 
 
	.hotel_name {
		font-weight: 600;
		font-size: 20px;
	}

	.sister-logo {
		padding: 15px 30px;
		border: 2px solid #ccc;
		margin-top: 10px;
	}

	//	======contact_footer========
	.contact_footer {
		background-color: #fff;
		color: $white;

		.footer-content {
			img {
				@media screen and (min-width: 992px) {
					padding-right: 30px;
					border-right: 1px solid $book;
				}
			}

			margin-bottom: 20px;

			p {

				margin-bottom: 10px;
				margin-top: 0;
			}
		}

		.all_detail_footer {
			a {
				color: #515151;
			}

			.img-icon {
				display: inline-block;
			}

			padding: 50px 0;

			.detail_contact {
				text-align: center;
				margin-top: 0;
				margin-bottom: 0;

				h2 {
					font-size: 26px;
					margin: 2px 0;
					text-transform: uppercase;
				}

				h3 {
					font-size: 18px;
					margin: 2px 0;
					color: rgba(255, 255, 255, 0.6);
				}

				p {
					font-size: 18px;
					line-height: 1.7em;
				}
			}

			.list_contactfoot {
				.row {
					margin-left: 0;
				}

				margin-top: 30px;

				.address {
					background-image: url('../images/place.png');
					background-repeat: no-repeat;
					padding-left: 40px;
					background-position: left center;
					font-size: 16px;
					background-size: 20px;
					margin-bottom: 25px;
					font-weight: 300;
					line-height: 2em;
				}

				.list_social {
					border-top: solid 1px rgba(255, 255, 255, 0.24);
					margin-top: 40px;
					padding-top: 30px;

					a {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						top: 0;
						color: $white;
					}
				}

				ul {
					li {
						margin-top: 10px;
						font-weight: 300;
						line-height: 1em;
						background-repeat: no-repeat;
						background-size: 30px;
						//                        padding-left: 40px;
						background-position: left center;
						font-size: 16px;
						padding-left: 0;
					}

					.call {
						background-image: url('../images/contact.png');
					}

					.fax {
						background-image: url('../images/fax.png');
					}

					.mail {
						background-image: url('../images/outlook.png');
					}

					.facebook {
						background-image: url('../images/facebook.png');
					}

					.line {
						background-image: url('../images/line.png');
					}

					.twitter {
						background-image: url('../images/twitter.png');
					}

					.ig {
						background-image: url('../images/instagram.png');
					}
				}
			}
		}
	}

	.google_map {
		margin-bottom: -6px;
	}

	.copyright {
		padding: 40px 0;
		background-color: $secondcolor;

		a {
			color: $white;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
}

@media (min-width: 767px) and (max-width: 991px) {
	.bht_footer {}
}

@media (min-width: 1200px) {
	.bht_footer {
		.contact_footer {
			.all_detail_footer {
				padding: 50px 55px;
			}
		}
	}
}

@media (min-width: 992px) {
	.bht_footer {
		.contact_footer {
			.all_detail_footer {
				.logo_footer {
					border-right: 1px solid $bdfoot;
				}
			}
		}
	}

}

@media (max-width: 991px) {
	.bht_footer {
		.logo_footer {
			margin-bottom: 30px;

			img {
				margin: 0 auto;
			}
		}

		.detail_contact {
			text-align: center;
		}
	}
}

@media (max-width: 767px) {
	.bht_footer {}
}
