.bht_content {
	.section_about {
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url('../images/bgbody.jpg');

		.about_flex {
			transition: none;

			li {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.content_about {
			background-color: #E6E6E6;
			position: absolute;
			width: 55%;
			right: 5%;
			top: 110px;
			padding: 80px;
			z-index: 20;
			box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, .3);

			.head_title {
				text-align: center;

				img {
					margin: 0 auto 15px;
				}

				h5 {
		@include font-size(30px,
						36px);
					font-weight: 600;
					font-family: $headfont;
					color: $white;
					margin-bottom: 0;
				}

				.title {
					font-size: 36px;
					font-weight: 600;
					margin-bottom: 30px;
					@include font-size(30px,
					36px);

					&:after {
						width: 30%;
					}

					span {
						@include font-size(30px,
						36px);
						font-family: $headfont;
						color: $maincolor;
					}

					&::after {
						content: none !important;
					}
				}

			}

			&:after {
				content: '';
				background-image: url('../images/shap.png');
				position: absolute;
				bottom: -20px;
				right: -20px;
				width: 70px;
				height: 70px;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.bht_content {
		.about_flex {
			li {
				background-attachment: fixed;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.bht_content {
		.section_about {
			.content_about {}
		}
	}
}

@media screen and (max-width: 991px) {
	.bht_content {
		.section_about {
			.content_about {
				position: relative;
				width: auto;
				top: 0;
				left: 0;
				padding: 50px 20px;
			}
		}
	}
}


@media screen and (max-width: 767px) {
	.bht_content {
		.section_about {
			.about_flex {
				li {
					height: 250px;
				}
			}
		}
	}
}

@media screen and (min-width: 1300px) {
	.bht_content {
		.section_about {
			.content_about {
				width: 40%;
				right: 8%;
			}
		}
	}
}

@media screen and (min-width: 1600px) {
	.bht_content {
		.section_about {
			.content_about {
				.head_title {
					.title {
						font-size: 36px;
						font-weight: 600;
					}
				}
			}
		}
	}
}
