.title_room {
	margin-bottom: 50px;
	position: relative;

	.title {
		@include font-size(28px, 45px);
		font-family: $headfont;
		display: inline-block;
		color: $white;

		span {
			font-family: $headfont;
			font-style: italic;
			color: $white;
		}

		&:after {
			//            content: '';
			position: absolute;
			top: -45%;
			left: 0;
			right: 0;
			margin: 0 auto;
			background-image: url('../images/title.png');
			background-repeat: no-repeat;
			height: 90px;
			width: 90px;
			background-size: 90px;
			background-position: center;
			display: block;
		}
	}
}

.section_room {
	.content_listroom {
		.name {
			font-size: 34px;
		}

		.link_room > a {
			font-size: 18px;
			padding: 15px 40px;
		}
	}
}

.content_listroom {
	.name {
		text-align: center;
		font-family: $headfont;
		color: $black;
		font-size: 26px;

		span {
			font-family: $headfont;
			color: $black;
			font-style: italic;
		}

		&:after {
			position: relative;
			content: '';
			display: block;
			width: 35px;
			height: 2px;
			background-color: $book;
			margin: 20px auto;
		}
	}

	.link_room {
		margin-top: 15px;
		text-align: center;

		&:hover {
			a {
				color: #fff;
			}
		}

		> a {
			color: $book;
			text-transform: uppercase;
			font-size: 16px;
			padding: 7px 30px;


			border: 1px solid $book;
			//            border-radius: 20px;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: $book;
				color: #fff !important;
				transition: all 0.3s ease-in-out;
			}
		}

		> span {
			color: $maincolor;
			text-transform: uppercase;
			font-size: 12px;
			padding: 7px 30px;
			border: 1px solid $maincolor;
			border-radius: 20px;
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: $maincolor;
				color: $white;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

.bht_content {
	.section_headroom {
		padding: 60px 0;

	}

	.section_room {
		.lSSlideOuter {
			.lSPager.lSpg {
				margin: 0 !important;
				line-height: 1px;

				> li {
					&.active {
						a {
							border-radius: 20px;
							background-color: $book;
						}
					}

					a {
						background-color: $bar;

						&:hover {
							background-color: $maincolor;
							border-radius: 20px;
						}
					}
				}
			}
		}

		.rooms_flex {
			li {
				position: relative;

				&:hover {
					.content_listroom {
						&._cover_top {
							&::before {
								content: "";
								border-style: solid;
								border-width: 30px 35px 30px 0;
								border-color: transparent #E6E6E6 transparent transparent;
								position: absolute;
								z-index: 999;

								text-align: center;
								margin: 0 auto;
								transform: rotate(90deg) translateX(-50%) translateY(-50%);
								display: block;
								left: 44%;
								top: -30px;

								@media screen and (max-width: 1199px) {
									display: none;
								}
							}
						}

						&._cover_bottom {
							&::after {
								content: "";
								border-style: solid;
								border-width: 30px 35px 30px 0;
								border-color: transparent #E6E6E6 transparent transparent;
								position: absolute;
								z-index: 999;
								bottom: -29px;
								text-align: center;
								margin: 0 auto;
								transform: rotate(-90deg) translateX(-50%) translateY(-50%);
								display: block;
								left: 52%;

								@media screen and (max-width: 1199px) {
									display: none;
								}
							}
						}

						position: relative;
						background-color: #E6E6E6;
						color: $white;
						transition: background 0.3s ease-in-out;

						.name {
							color: $white;

							span {
								color: $white;
							}

							&:after {
								background-color: $white;
							}
						}

						.detail_listroom {
							p {
								color: $white;
							}
						}

						.link_room {
							a {
								border-color: $white;
								color: $white;

								&:hover {
									border-color: $white;
									color: $white;
								}
							}
						}


					}
				}
			}

			.img_room {
				height: 404px;

				@media screen and (max-width: 991px) {
					height: 300px !important;
				}
			}

			.content_listroom {
				background: #F6F6F6;
				padding: 50px 80px;

				.detail_listroom {

					min-height: 250px;

					p {
						font-weight: 300;
						font-size: 18px;
						color: $black;
						line-height: 30px;
					}
				}
			}
		}
	}
}

.content_room {
	p {
		font-weight: 300;
		font-size: 18px;
		line-height: 33px;
	}
}

@media screen and (min-width: 1300px) {
	.bht_content {
		.section_headroom {
			.content_room {
				padding: 0 100px;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.bht_content {
		.section_room {
			.container-fluid {
				padding: 0;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.bht_content {
		.section_room {
			.lSSlideOuter {
				.lSPager.lSpg {
					background-color: $bar;

					> li {
						padding: 0;
						width: 16.7%;

						a {
							border-radius: 0;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.bht_content {
		.section_room {
			.lSSlideOuter {
				.lSPager.lSpg {
					background-color: $bar;

					> li {
						padding: 0;
						width: 20%;

						a {
							border-radius: 0;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1600px) {
	.bht_content {
		.section_room {
			.lSSlideOuter {
				.lSPager.lSpg {
					> li {
						width: 16.65% !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.bht_content {
		.section_room {
			padding: 0 15px;

			.rooms_flex {
				.content_listroom {
					padding: 50px 20px;
				}
			}
		}
	}
}
