.bht_content {
    position: relative;
}
@import "about";
@import "facilities";
@import "rooms";

@media (min-width: 992px) {
    
}


@media (min-width: 1200px) {

}


@media (max-width: 991px) {
    
}

@media (max-width: 320px) {

}
@media (max-width: 767px) {  
    
}


@media (max-width: 991px) and (min-width: 768px) {
    
}


@media (min-width: 767px) {

}


@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: landscape) {

}
@media (min-width: 1600px) {
    
}
@media (max-width: 1199px) and (min-width: 992px) {
    
}
