.bht_header {
    .navbar-brand {
        height: auto;
        img {
            max-width: 200px;
        }
    }
    .right_bar {

        position: relative;
        z-index: 10;
        .member-bar {
            display: inline-block;
            ul {
                background-image: url('../images/user.png');
                background-repeat: no-repeat;
                background-position: 1% center;
                background-size: 20px;
                padding-left: 30px;
                > li {
                    padding: 0 2px;
                    span {
                       color: #515151;
                    }
                    a {
                       color: #515151;
                        font-size: 12px;
                        font-family: $menu;
                        line-height: 3em;
                    }
                }
            }
        }
        .lang {
            display: inline-block;
            color: #515151;
            font-family: $menu;
            font-size: 12px;
            margin-left: 15px;
        }
    }
    .navbar-default {
        background-color: $navbar;
        border: none;
        border-radius: 0;
        .navbar-nav {
            > li {
                > a {
                    color: #515151;
                    text-transform: uppercase;
                    font-family: $menu;
                    font-size: 12px;
                    letter-spacing: 1px;
                }
            }

        }
    }
    .navbar-header {
        float: none;
    }
}

@media screen and (min-width: 992px) {
    .bht_header {
		.menu-primary-menu-container,
		.menu-sub-menu-container {
			border-left: 1px solid #c3c3c3;
		}
        .main_menu {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 1030;
            transition: top 0.2s ease-in-out;
        }
        .nav-up {
            top: -128px;
        }
        .right_bar {
            position: absolute;
            right: 0;
            z-index: 10;
            top: 0;
            z-index: 1040;
            height: 63.6px;
            padding: 0 30px 0 15px;
            .member-bar {
                ul {
                    > li {
                        a {
                            line-height: 5.3em;
                        }
                    }
                }
            }
        }
        .nav.navbar-nav {
            float: none;
            padding-left: 100px;
            text-align: right;
            padding-right: 250px;
            position: relative;
            > li {
                float: none;
                display: inline-block;
                > a {
                    padding: 15px 17px;
                    line-height: 2.8em;
                }
            }

        }
        .btn_navbook {
            position: absolute;
            right: 0;
            top: 64px;
            height: 63.3px;
            background-color: $book;
            text-align: center;
            padding: 0 50px;
            a {
                color: #fff;
                font-size: 18px;
                text-transform: uppercase;
                
                font-weight: 300;
                line-height: 63px;
            }
        }
        ._bgone {
            > li {
                &:last-child {
                    position: relative;
                    > a {
                        &:after {
                            content: '|';
                            position: absolute;
                            right: -15px;
                        }
                    }
                }

            }
        }
        ._bgtwo {
            > li {
                > a {
                    padding: 15px 18px !important;
                }
            }
        }
        .navbar-header {
            position: absolute;
            left: 45px;
            top: 15px;
        }
        .navbar-collapse {
            padding-left: 300px;
            padding-right: 0;
        }
        .con-tab {
            padding: 0;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .bht_header {
        .right_bar {
            padding: 0 15px !important;
            .member-bar {
                ul {
                    background-size: 20px;
                    padding-left: 30px;
                    > li {
                        a {
                            font-size: 11px;
                            line-height: 5.5em;
                        }
                    }
                }
            }
            .lang {
                margin-left: 10px;
            }
        }
        .navbar-collapse {
            padding-left: 220px;
            padding-right: 0;
        }
        .navbar-header {
            left: 30px;
        }
        .nav.navbar-nav {
            padding-left: 0;
            padding-right: 215px;
            > li {
                > a {
                    padding: 15px 8px !important;
                    font-size: 10px;
                    line-height: 3.5em;
                }
            }
            .btn_navbook {
                padding: 0 40px;
            }
        }

        .navbar-brand {
            > img {
                width: 150px;
            }
        }
    }
}


@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .bht_header {
        .nav.navbar-nav {
            padding-left: 0;
            > li {
                > a {
                    padding: 15px 8px !important;
                }
            }

        }
    }
}

@media screen and (max-width: 991px) {
    body.out {
        nav {
            .navbar-toggle {
                &:hover {
                    background-color: transparent !important;
                }
                background-color: transparent !important;
            }
        }
    }
    .bht_header {
        .right_bar {
            background-color: $maincolor;
            text-align: right;
            padding-right: 30px;
        }
        .navbar-brand {
            > img {
                width: 150px;
            }
        }
        .navbar-toggle {
            background-color: transparent !important;
            margin-top: 15px;
            margin-bottom: 15px;
            .icon-bar {
                background-color: $white;
            }
        }
        .nav.navbar-nav {
            > li {
                border-bottom: 1px solid $black;
                border-bottom-style: dotted;
            }

        }
        .navbar-collapse {
            background-color: $secondcolor;
            border-color: $secondcolor;
        }
    }
}

@media screen and (max-width: 767px) {
    .bht_header {
        .nav.navbar-nav {
            > li {
                > a {
                    line-height: 2em;
                }
            }

        }
    }
}

@media screen and (min-width: 1600px) {
    .bht_header {
        .nav.navbar-nav {
            text-align: center;
            > li {
                &:last-child {
                    a {
                        &:after {
                            content: none;
                        }
                    }
                }
                > a {
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
            }
        }
        .btn_navbook {
            top: 63px;
        }
    }
}

.navbar-default {
    .navbar-nav {
        > .active {
            > a {
                background-color: transparent;
                &:focus {
                    background-color: transparent;
                }
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}
