@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import "fonts";
@import "variables";
@import "responsivefontsize";
@import "component";



//header
@import "header";


//content
@import "content";

//footer
@import "footer";
//@import "customflexslider";

// custom-page
@import "custom-page";

@import 'rate';
.bht_page .container-box-search .box_search input {
    height: 60px!important;
}

._pd_0 {
	padding: 0;
}