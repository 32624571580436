$mainfont: 'Roboto', sans-serif;
$headfont: 'Arapey',
sans-serif !default;
$otherfont: 'Lato Hairline',
sans-serif !default;
$menu: 'HK Nova Medium R',
sans-serif !default;
$hero: 'Vidaloka',
sans-serif !default;


$maincolor: #d8d8d8;
$secondcolor: #e9e9e9;
$bdfoot: #ab5673;
$navbar: #e9e9e9;
$book: #795F44;
$white: #515151;
$black: #181818;
$font: #6b7980;
$otherfont: #c7c5c2;
$bar: #c7c5c2;


._mg_0_0_20{
    margin-bottom: 20px;
}


._pd_0_10 {
    padding: 0 10px;
}

._pd_0_20 {
    padding: 0 20px;
}
