.bht_page {
	.bht_header {
		.container-box-search {
			padding: 20px 0;
			.title_box_search {
				margin-bottom: 20px;
			}
		}
	}
	.section_room {
		height: auto;
	}
	.bht_content {
		.section_headroom {
			padding: 0;
			.content_room {
				margin-bottom: 50px;
				line-height: 2em;
			}
		}
	}
	.sub_boxsearch {
		position: absolute;
		bottom: 0;
		width: 100%;
		top: auto !important;
		z-index: 100;
		height: 87.4px;
		overflow: hidden;
		.bg_boxpage {
			background-image: url('../images/headpage.jpg');
			background-size: cover;
			background-position: center;
			height: 450px;
			width: 100%;
			position: absolute;
			bottom: -4px;
			filter: blur(4px);
		}
	}
	.container-box-search {
		.box_search {
			&:before {
				content: none;
			}
			input {
				height: 45px !important;
			}
		}
	}
	.content_allroom {
		.section_room {
			padding: 15px 0;
			//			&:hover {
			//				.img_room {
			//					transform: scale(1.1);
			//					transition: all 0.3s ease-in-out;
			//				}
			//			}
			.center {
				.item_room {
					overflow: hidden;
					a {
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
					}
					.img_room {
						transition: all 0.3s ease-in-out;
					}
				}
			}
		}
	}
	.section_facilities {
		background: none !important;
		padding: 0;
		.title {
			margin-bottom: 30px !important;
		}
	}
	.contentpage_faq {
		.img_faq {
			margin-bottom: 30px;
		}
	}
	.head_boxpage {
		background-color: $maincolor;
		h3 {
			line-height: 40px;
			color: $white;
			font-weight: 400;
		}
	}
	#page-title.page-title-parallax {
		position: relative;
		background-size: cover;
		height: 300px;
		background-image: url('../images/headpage.jpg');
		h1 {
			font-family: $headfont;
			text-shadow: 1px 1px 20px $black;
			font-weight: 600;
			padding: 0;
			margin: 0;
			line-height: 1;
			letter-spacing: 1px;
			color: #fff;
			text-transform: uppercase;
			font-size: 40px;
			letter-spacing: 2px;
			z-index: 10;
			position: relative;
			position: absolute;
			width: 100%;
			top: 40%;
		}
	}
	.bht_content {
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		padding: 70px 0;
		background-image: url('../images/bgbody.jpg');
	}
	// =======accommodtaion=============
	.section_room_page {
		.card_room {
			margin-bottom: 50px;
		}
		.card {
			background-color: #fff;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			overflow: hidden;
			transition: box-shadow 0.5s;
			position: relative;
			height: 340px;
			a {
				text-decoration: none;
			}
			&:hover {
				box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
				.card_img {
					height: 100px;
					position: relative;
					.card_img_bg {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background-size: cover;
						background-position: center;
						opacity: 1;
						transform: scale(1);
						transition: opacity 0.5s, transform 0.5s;
						opacity: 0.6;
						transform: scale(1.2);
					}
				}
				.card_body {
					height: 275px;
					.card_description {
						top: 10px;
						height: 80px;
					}
				}
			}
			.card_img {
				height: 245px;
				overflow: hidden;
				background-color: #000;
				transition: height 0.5s;
				position: relative;
				.card_img_bg {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-size: cover;
					background-position: center;
					opacity: 1;
					transform: scale(1);
					transition: opacity 0.5s, transform 0.5s;
				}
			}
			.card_body {
				position: relative;
				height: 120px;
				padding: 20px;
				transition: height 0.5s;
				.content {
					position: relative;
				}
				.card_btn {
					text-align: right;
				}
				.card_title {
					margin: 0;
					padding: 0 0 10px 0;
					font-size: 22px;
					font-weight: bold;
					text-transform: uppercase;
				}
				.card_subtitle {
					margin: 0;
					font-size: 16px;
				}
				.card_description {
					opacity: 0;
					transform: translateY(45px);
					transition: opacity 0.3s, transform 0.3s;
					transition-delay: 0s;
				}
			}
			&:hover .card_description {
				opacity: 1;
				transform: translateY(0);
				transition-delay: 0.2s;
			}
			.link_room {
				margin-top: 30px;
			}
		}
	}
	.page_gall {
		.card {
			&:hover {
				.card_img {
					height: 190px;
				}
			}
		}
	}
	//==========single-accom===========
	.single_room {
		.lSSlideOuter .lSPager.lSpg > li.active a,
		.lSSlideOuter .lSPager.lSpg > li:hover a {
			background-color: $maincolor;
		}
		.content_fachome {
			z-index: 100;
		}
		.amenity {
			border-top: 1px solid rgba(204, 204, 204, 0.32);
			padding-top: 50px;
			.section_room {
				padding: 0;
			}
			.item_room {
				padding: 0;
				.tab_name {
					position: relative;
					text-align: center;
					background: none;
					color: $maincolor;
					padding-bottom: 70px;
					border-bottom: 1px solid rgba(204, 204, 204, .38);
					ul {
						display: block !important;
					}
				}
			}
			.list_amenity {
				li {
					@media screen and (max-width: 991px) {
						margin-left: 30px;
					}
					font-size: 16px;
					line-height: 1.5em;
					background-size: 20px !important;
					background-position: 0 2px !important;
					background-repeat: no-repeat;
					padding-left: 35px;
					position: relative;
					padding-bottom: 27px;
					&:before {
						content: '';
						width: 35px;
						height: 35px;
						position: absolute;
						left: -8px;
						top: -5px;
						border: 1px solid $maincolor;
					}
					i {
						margin-right: 5px;
						color: $maincolor;
					}
				}
			}
		}
		.head_amen {
			margin-top: 70px;
			.title_room {
				margin-bottom: 30px;
				.title {
					margin-bottom: 0;
					&:after {
						top: -22%;
					}
				}
			}
		}
		.other_detail {
			margin-top: 50px;
			padding: 0 15px;
		}
	}
	.section_room {
		text-align: center;
		padding: 0 0 40px 0 !important;
		.item_room {
			a {
				.img_room {
					bottom: 0;
				}
				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					font-size: 20px;
					color: $white;
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
				}
			}
		}
		.center {
			.item_room {
				overflow: inherit !important;
				a {
					top: -70px;
					bottom: -70px;
					transition: all 0.3s ease-in-out;
					.img_room {
						box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
					}
					&:after {
						transition: all 0.3s ease-in-out;
						content: '';
					}
					&:hover {
						&:after {
							content: '';
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							top: 0;
							z-index: 9;
							background-color: rgba(24, 24, 24, 0.7);
							transition: all 0.3s ease-in-out;
						}
						i {
							opacity: 1;
							z-index: 10;
							transition: opacity 0.3s ease-in-out;
						}
					}
				}
				.tab_name {
					ul {
						display: inline-block;
						position: relative;
						li {
							font-family: $headfont;
							padding-left: 15px;
							padding-right: 15px;
							font-size: 18px;
							color: $book;
							&:first-child {
								border-right: 1px solid rgba(204, 204, 204, 0.38);
							}
							&:nth-child(2) {
								position: relative;
							}
							img {
								display: inline-block;
								width: 55px;
								vertical-align: text-bottom;
							}
							strong {
								font-family: $headfont;
								@include font-size(20px, 35px);
								line-height: 0.2em;
							}
							p {
								font-size: 18px;
								font-weight: bold;
								text-align: left;
								font-family: $headfont;
								color: $black;
							}
						}
					}
				}
			}
		}
	}
	//=============restaurant===========
	.attrac {
		padding-bottom: 40px !important;
		.room_item-forward {
			position: relative;
			bottom: inherit;
			left: inherit;
			right: inherit;
			z-index: 1;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			h2 {
				a {
					font-size: 18px;
					color: $white;
					font-weight: 400;
				}
			}
			span {
				font-weight: 300;
			}
		}
		.room_list {
			margin-bottom: 50px;
		}
		.page_content {
			padding-bottom: 50px;
			[class*=' imghvr-'] {
				background-color: transparent;
			}
			[class^=imghvr-] {
				background-color: transparent;
			}
		}
		.btnbook_room {
			position: absolute;
			bottom: 10px;
			right: 15px;
			.btn-ghost {
				border-radius: 2px;
				background-color: $maincolor;
				border-color: $maincolor;
			}
		}
	}
	.white-popup {
		background-size: cover;
		background: url('../images/bgbody.jpg') center;
		padding: 50px;
		width: 70%;
		margin: 20px auto;
		position: relative;
		.attrac-decs {
			p {
				color: $font;
			}
		}
	}
	.head_meeting {
		> img {
			width: 100px !important;
		}
	}
	.content_meeting {
		padding: 0 0 50px 0 !important;
		> p {
			text-align: left !important;
		}
	}
	.other_meeting {
		font-size: 16px;
		color: $black;
		ul {
			padding-left: 15px;
			> li {
				list-style: none;
			}
		}
		a {
			color: $secondcolor;
		}
		strong {
			color: $maincolor;
		}
	}
	.meeting_all {
		margin-bottom: 50px;
		.room-amenity {
			padding-top: 0;
			p {
				display: block;
				color: $black;
			}
		}
	}
	.detail_shopping {
		strong {
			color: $maincolor;
		}
	}
	.room-amenity {
		a {
			color: $maincolor;
		}
	}
}
// ========== gallery=============
.section_room_page {
	.card_gallery {
		&:hover {
			.card_img {
				height: 170px;
			}
		}
	}
}
// contact
.email-form {
	.field-text {
		margin-top: 20px;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
	}
	input.form-control {
		background: none;
		border: 1px solid $maincolor;
		height: 40px;
		line-height: 40px;
		padding: 10px 20px;
		font-size: 14px;
		font-family: $mainfont;
		width: 100%;
		color: $font;
		border-radius: 0;
	}
	textarea.form-control {
		background: none;
		border: 1px solid $maincolor;
		padding: 10px 20px;
		font-size: 14px;
		font-family: $mainfont;
		width: 100%;
		color: $font;
		border-radius: 0;
	}
	.bth_fac {
		margin-top: 30px;
	}
	.btn_component {
		background-color: $maincolor;
		border-color: $maincolor;
		border-radius: 0;
		color: $white;
		font-family: $mainfont;
		text-transform: uppercase;
		border: none;
		font-size: 16px;
		padding: 6px 40px;
		border-radius: 0;
	}
}
.all_contact {
	li {
		vertical-align: top;
		list-style-type: none;
		margin: 0;
		padding: 0px 0px 10px 0;
		vertical-align: middle;
		color: $font;
		font-size: 16px;
		background-size: 22px !important;
		strong {
			color: $maincolor;
			display: block;
		}
	}
	.list_phone {
		strong {
			display: inline-block;
		}
	}
	.list_email {
		a {
			color: $font;
		}
	}
}
.page-template-template-implement .bht_content,
.bht_content.template-booking {
    background: #fff !important;
	.b2h_rate_v3 {
//		background: #fff !important;
	}
}
.head-address {
	margin: 20px 0;
	h3 {
		font-family: $mainfont;
		text-transform: uppercase;
		color: $maincolor;
		font-size: 25px;
		font-weight: bold;
	}
}
.detail_contact {
	margin-top: 20px;
	margin-bottom: 20px;
	p {
		font-size: 16px;
		font-weight: 300;
	}
}
.office {
	margin: 20px 0;
	h4 {
		font-family: $mainfont;
		text-transform: uppercase;
		color: $maincolor;
		font-size: 20px;
		font-weight: bold;
	}
}
.map_home {
	height: 500px;
}
.section_map {
	height: 350px;
	background-size: cover;
	position: relative;
	a {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}
}
.address-contact {
	.name {
		font-size: 25px;
		text-transform: uppercase;
		color: $maincolor;
	}
}
.page_contact {
	padding-bottom: 50px;
}
.img_mappage {
	margin-top: 30px;
}
span.wpcf7-list-item {
	display: block;
}
// ======brackpoint===========
@media (min-width: 1200px) {}
@media (min-width: 992px) and (max-width: 1199px) {}
@media (min-width: 992px) {
	.header_book {
		.navbar-nav {
			> li {
				> a {
					line-height: 40.5px;
				}
			}
		}
		.navbar-fixed-top {
			.navbar-nav {
				> li {
					> a {
						line-height: 25.5px !important;
					}
				}
			}
		}
		.bht_content {
			padding-top: 100px !important;
		}
	}
	.bht_page {
		.attraction {
			width: 550px;
			margin: 0 auto;
		}
		.single_room {
			padding-top: 70px;
			.container-fluid {
				padding-right: 0;
			}
			.row {
				margin: auto;
			}
			.content_fachome {
				top: 20px;
			}
		}
		.top_bar {
			position: absolute;
			width: 100%;
		}
		#page-title.page-title-parallax {
			background-position: center !important;
		}
		.navbar-inverse.navbar-fixed-top {
			position: fixed;
		}
	}
	.page_accom {
		padding-top: 100px !important;
		.gallery_name {
			h2 {
				font-family: Lato, sans-serif;
				font-weight: 600;
				padding: 0 0 20px 0;
				margin: 0;
				line-height: 1;
				color: #912e00;
				text-transform: uppercase;
				font-size: 28px;
				letter-spacing: 2px;
				z-index: 50;
				position: relative;
			}
		}
	}
}
@media (max-width: 991px) {
	.bht_page {
		.in_room {
			.section_room {
				height: auto;
			}
		}
		.container-box-search {
			background-color: $maincolor;
			&:before {
				content: none;
			}
		}
		.copyright {
			margin-bottom: 0;
		}
		.content_detail {
			padding: 0 15px 50px 15px;
		}
		.boxsearch_page {
			background-color: $maincolor;
			padding-bottom: 30px;
		}
		.contact-form {
			margin-top: 30px;
		}
		.underline-sub-restaurant {
			text-align: center;
			margin-top: 30px;
		}
	}
	.img_faq {
		margin-top: 30px;
		img {
			margin: 0 auto;
		}
	}
}
@media (max-width: 767px) {
	.bht_page {
		.img_fac {
			margin-bottom: 50px;
			margin-left: 15px;
			margin-right: 15px;
		}
		.in_room {
			.section_room {
				padding: 100px 0;
			}
		}
		.list_amenity {
			padding: 0 15px;
		}
		#page-title.page-title-parallax {
			height: 180px;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background-color: rgba(51, 51, 51, 0.26);
			}
			background-size: cover !important;
			background-position: center center !important;
			h1 {
				font-size: 22px;
			}
		}
		#page-title {
			text-align: center;
		}
		.bht_content {
			padding: 30px 0;
		}
		.title_room {
			margin-top: 30px;
			margin-bottom: 70px;
			.title {
				&:after {
					top: -90%;
				}
			}
		}
		.bht_content .section_facilities .single_flex li {
			height: 250px;
		}
		.single_room {
			.content_fachome {
				margin-top: 50px;
			}
		}
	}
	.section_room {
		height: auto !important;
		.tab_name {
			bottom: 0 !important;
		}
	}
	.bht_page .detail_faq .panel-title a {
		padding: 15px 35px 15px 15px;
	}
	.white-popup {
		padding: 30px !important;
		width: 90% !important;
	}
}
@media (min-width: 767px) {
	.bht_page {
		#page-title.page-title-parallax {
			.breadcrumb {
				left: auto !important;
				right: auto !important;
			}
		}
		.list_gallery {
			figure {
				display: block;
			}
			.img_long {
				display: block;
				z-index: 9;
				width: 100%;
				background-size: cover;
				background-position: center;
			}
		}
	}
}
@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: landscape) {
	.section_room {
		.tab_name {
			h3 {
				font-size: 14px;
				strong {
					font-size: 20px !important;
				}
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.bht_page {
		.room_item-forward {
			h2 {
				a {
					font-size: 14px;
				}
			}
		}
	}
}
@media (min-width:1199px) {
	.regiser_form {
		width: 800px;
		margin: 0 auto;
		padding: 30px;
		box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.3);
	}
}
