.bht_content {
	.section_facilities {
		background-image: url('../images/bgbody.jpg');
		background-repeat: repeat;
		position: relative;

		.content_fachome {
			background-color: $secondcolor;
			padding: 100px 80px 150px 80px;
			width: 37%;
			position: absolute;
			left: 10%;
			top: 90px;
			z-index: 20;
			box-shadow: 0px 8px 20px 2px rgba(0, 0, 0, .3);

			&:before {
				content: '';
				background-image: url('../images/shap.png');
				position: absolute;
				bottom: -20px;
				left: -20px;
				width: 70px;
				height: 70px;
			}

		}

		.facilities_flex {
			transition: none;

			li {
				height: 500px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.single_flex {
			transition: none;

			li {
				height: 500px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	.head_title {
		.title {
			margin-bottom: 30px;
			font-family: $headfont;
			@include font-size(24px, 46px);
			color: $white;
			position: relative;
			display: inline-block;
			letter-spacing: 1px;
			//					&:after {
			//						position: relative;
			//						content: '';
			//						display: block;
			//						width: 50%;
			//						height: 1px;
			//						background-color: $white;
			//						margin: 30px 0;
			//					}
		}
	}

	.detail {
		margin-top: 20px;
		font-weight: 300;
		display: block;
		position: relative;
		color: $white;
		font-size: 18px;
		line-height: 30px;

	}
}


@media screen and (min-width: 992px) {
	.bht_content {
		.section_facilities {
			padding: 150px 0 90px;
		}

		.facilities_flex {
			li {
				background-attachment: fixed;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.bht_content {
		.section_facilities {
			.content_fachome {
				padding: 50px 70px 120px;
				width: 45%;
				left: 6%;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.bht_content {
		.section_facilities {
			padding: 50px 0;

			.content_fachome {
				position: relative;
				width: auto;
				padding: 50px 20px;
				margin: 0 15px;
				top: 0;
				left: 0;
			}
		}
	}
}


@media screen and (max-width: 767px) {
	.bht_content {
		.section_facilities {
			.facilities_flex {
				li {
					height: 250px;
				}
			}
		}
	}
}
