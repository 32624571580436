$layout-max-width: 720px;
$font-bound-min: 320px; // will override defaults
$font-bound-max: 720px; // will override defaults
// ==============================
// Font size mixin
// ==============================
//  default font scale bounding
$font-lock-min: 400px !default;
$font-lock-max: 1000px !default;
//  font-size 
@mixin font-size( $min, // font-size 
$max: null, // optional maximum font size
$lock-min: $font-lock-min, // optional minimum viewport width 
$lock-max: $font-lock-max // optional maximum viewport width 
) {
    & {
        font-size: $min;
    }
    @if $max {
        @media (min-width: $lock-min) and (max-width: $lock-max) {
            & {
                font-size: calc( #{$min} + #{num($max) - num($min)} * (100vw - #{$lock-min}) / #{num($lock-max) - num($lock-min)});
            }
        }
        @media (min-width: $lock-max) {
            & {
                font-size: $max;
            }
        }
    }
}

//  strip units helper function
@function num($input) {
    @return $input/($input * 0 + 1);
}


// ==============================
//  set default scaling
// ==============================
p {
    @include font-size(14px, 16px);
}

h1 {
    @include font-size(28px, 34px);
}

h2 {
    @include font-size(32px, 40px);
}
