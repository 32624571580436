body {
	
    font-family: $mainfont;
		color: $black;
		font-weight: 300;
		
}
a{
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}


//reset

* {
    font-family: $mainfont;
    font-weight: 400;
}
.navbar {
    margin: 0;
}
.flexslider {
	border: 0;
	margin: 0;
	background: transparent;
    border-radius: 0;
}
.fix-height {
	overflow: hidden;
}

body.open_boxsearch {
	overflow: hidden;   
}

.picker__frame {
	overflow: hidden;
}
.picker {
    position: relative !important;
}
.picker__holder {
    min-width: 220px !important;
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(51, 51, 51, 0.35);
		z-index: 3;
}

//helper

._padDefault {
	padding: 50px 0;
}
._nopad {
	padding: 0 !important;
}
._nomar {
	margin: 0 !important;
}

@media (min-width: 992px) {
		._padDefault {
			padding: 70px 0;
		}
		
	._clpad {
		padding: 0 !important;
	}  
	._bgone {
	background-color: $navbar;
}
._bgtwo {
	background-color: $maincolor;
}
}
